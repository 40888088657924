.react-time-picker {
  display: inline-flex;
  position: relative;
  font-family: "Inter";
  font-size: "14px";
}
.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-time-picker--disabled {
  background-color: #f1f6fc;
  color: #5c7e99;
}
.react-calendar__navigation {
  background-color: #53aedf1a;
  border-radius: 8px;
}

.react-calendar__tile--active,
.react-calendar__tile--range,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeBothEnds {
  background: #53aedf1a !important;
  border-radius: 999px;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow,
.react-calendar__navigation__next-button {
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.react-calendar__tile {
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid #c0c7cc;
  border-radius: 8px;
  padding: 4px;
}
.react-time-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 7 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
}
.react-time-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-time-picker__inputGroup__input {
  width: 0.6em;
  height: calc(100% - 2px);
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-time-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-time-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}
.react-time-picker__button {
  border: 0;
  background: white;
  padding: 4px 6px;
}
.react-time-picker__button:enabled {
  cursor: pointer;
}
.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0074a5;
}
.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #5c7e99;
}
.react-time-picker__button svg {
  display: inherit;
  stroke: #53aedf;
}
.react-time-picker__calendar,
.react-time-picker__clock {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
}
.react-time-picker__calendar--closed,
.react-time-picker__clock--closed {
  display: none;
}
.react-time-picker__calendar {
  width: 250px;
  max-width: 100vw;
  background-color: white;
}
.react-time-picker__calendar .react-calendar {
  border-width: thin;
  padding: 10px;
  box-shadow: #5c7e9933 0px 4px 12px;
}
.react-time-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #c0c7cc;
}
