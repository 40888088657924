.vertical-timeline-element-content {
  box-shadow: none;
  border-color: black;
}

.vertical-timeline-element-content-arrow {
  border-right: 1px solid #dfedf9;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  position: absolute;
  right: 16px;
  top: 14px;
  color: #9797AA;
  font-weight: normal;
  font-size: small;
}
.vertical-timeline-element-content {
  border: 1px solid #dfedf9;
  border-radius: 8px;
}

.vertical-timeline-element {
  margin: 1em 0;
}

.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid white !important;
  -webkit-box-shadow: 2px 0px 6px -1px #dfedf9;
  box-shadow: 2px 0px 6px -1px #dfedf9;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 2px;
  background: #dfedf9;
}
