.rbc-time-view {
  border: none;
  font-family: Inter;
  font-size: x-small;
}

.rbc-day-slot .rbc-event {
  border-color: white;
}
.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}
.rbc-time-header-content {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.rbc-time-content > .rbc-day-slot {
  background-color: white;
}
.rbc-show-more {
  color: #53aedf;
}
.rbc-header {
  font-family: "Inter"; //"Nunito Sans";
  font-weight: bold;
  font-size: small;
  padding: 10px;
  min-height: unset;
  border-bottom: none;
}
.rbc-time-view .rbc-row {
  min-height: unset;
}
.rbc-time-header-content {
  border-left: 1px solid #dde0e1;
}
.bc-event .rbc-selected{
  height: auto !important
}
.rbc-toolbar .rbc-toolbar-label {
  font-size: medium;
  font-weight: bold;
  text-align: left;
  color: #53aedf;
  margin-top: 20px;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #dde0e1;
}
.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #dde0e1;
  overflow-y: auto;
  position: relative;
}
.rbc-day-slot .rbc-event-label {
  font-size: x-small;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #f1f6fc;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #dde0e1;
}
.rbc-timeslot-group {
  min-height: 100px;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.08);
  background-color: #fafcfe;
  border-color: #dde0e1;
}

.rbc-toolbar button {
  border: 1px solid #dde0e1;
  background-color: #ffffff;
  border-radius: 6px;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #dde0e1;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #dde0e1;
}
.rbc-month-view {
  border: 1px solid #dde0e1;
  border-radius: 6px;
}
.rbc-off-range-bg {
  background-color: #fafcfe;
  color: #1a202c;
}
.rbc-toolbar button:hover {
  color: #1a202c;
  background-color: #fafcfe;
  border-color: #d1ddf1;
}
.rbc-current-time-indicator {
  background-color: #df5553;
  height: 2px;
  border: none;
}
.rbc-current-time-indicator::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: -1;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #df5553;
  border: 1px solid #df5553;
  display: inline-block;
}
.rbc-today {
  background-color: #53aedf33;
}
