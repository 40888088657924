.react-date-picker__wrapper {
  border: thin solid #d1ddf1;
  border-radius: 6px;
  padding: 4px;
}

.react-datetime-picker__wrapper {

  border: thin solid #d1ddf1;
  border-radius: 6px;
  padding: 4px;
}