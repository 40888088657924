.tooltip {
  width: 400px;
  white-space: pre-wrap;
  display: block;
}

.react-tabs__tab {
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 8px 12px;
  margin: 0px 6px;
  cursor: pointer;
  color: #53aedf;
  font-weight: 500;
  border: solid 1px #f1f6fc;
  border-radius: 10px !important;
    margin-bottom: 12px;
}
.react-tabs__tab-list {
  border: none;
  padding: 0;
  font-family: "Inter";
}
.react-tabs__tab--selected {
  background-color: #f1f6fc;
  border: solid 1px #53aedf;
  font-weight: 500;
}
.react-tabs__tab:hover {
  background-color: #f1f6fc;
}
.react-tabs__tab:focus {
  background-color: #d5e3ec;
  box-shadow: none;
  border-color: none;
  outline: none;
}
.react-tabs {
  width: 100%;
}

.health-react-tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 500;
}

.health-react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0;
}
.health-react-tabs__tab--selected {
  color: #53aedf;
  font-weight: 500;
}
.health-react-tabs__tab-panel--selected {
  display: contents;
  width: 100%;
}
.health-react-tabs__tab-panel {
  width: 100%;
  display: contents;
}
.primary-tabs__tab {
  display: inline-block;
  border: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 12px 12px;
  cursor: pointer;
  color: #4c82a8;
  font-weight: 500;
  background-color: transparent;
}
.primary-tabs__tab-list {
  margin: 0 0 0px;
  padding: 0;
  font-family: "Inter";
  border-bottom: 2px solid;
  border-color: #d5e3ec !important;
}
.primary-tabs__tab--selected {
  color: #53aedf !important;
  font-weight: bold;
  border-bottom: 2px solid;
  border-color: #53aedf !important;
}
.primary-tabs__tab:hover {
  background-color: #d5e3ec;
}
.primary-tabs__tab:focus {
  box-shadow: none;
  outline: none;
}
.primary-tabs {
  width: 100%;
}
